/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import { NotionRenderer } from 'react-notion';
import "react-notion/src/styles.css";
import { useDispatch } from 'react-redux';
import { addAnchorClickListener, addBackClickListner } from './funtions';

function App() {
  const [response, setResponse] = useState({});
  const [notionId, setNotionId] = useState('/85f97cbf1f7c47e29e1fe19404c731d3');
  const dispatch = useDispatch();
  const { history } = dispatch;
  const { pushHistory, popHistory } = history;
  
  useEffect(() => {
    fetch(`https://notion-api.splitbee.io/v1/page${notionId}`)
      .then(res => res.json())
      .then((resJson) => {
        setResponse(resJson);
      });
  }, [notionId])

  useEffect(()=>{
    addAnchorClickListener("notion-page-link", setNotionId, pushHistory);
    addAnchorClickListener("notion-nav-breadcrumb ", setNotionId, pushHistory);
  }, [response])

  useEffect(() => {
    addBackClickListner(setNotionId, popHistory);
  }, [])
  
  return (
      <NotionRenderer
        blockMap={response}
        fullPage={true}
      />
  );
}

export default App;
