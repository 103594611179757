
export function addAnchorClickListener(className, setId = () => {}, pushHistory) {
let links = document.getElementsByClassName(className);
    Array.from(links).forEach((link) => {
    // Do stuff here
      link.addEventListener("click", async e => {
        e.preventDefault()
        let href = link.getAttribute("href")
        if (href === null) {
          href = "/85f97cbf1f7c47e29e1fe19404c731d3";
        }
        setId(href);
        await pushHistory(href)
      });
    });
}

export function addBackClickListner(setId = () => {}, popHistory) {
  window.history.pushState(null, document.title, window.location.href);
  window.addEventListener("popstate", async e => {
    const notionId = await popHistory();
    setId(notionId);
    window.history.pushState(null, document.title, window.location.href);
    e.preventDefault();
  });
}